
html {
}

body {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 10px;
}

.fastest {
  background-color: #cddccd;
  color: #222;
}

.slowest {
  background-color: #e0b8b8;
  color: #222;
}

.unsupported {
  background-color: #9c948a;
}

td {
    border: solid 1px white;
    text-align: right;
    min-width: 100px;
}

td:last-child {
  padding-right: 10px;
}

.average-row td {
  width: 70px;
  padding: 10px;
  background-color: #382373;
  color: white;
  border-bottom: solid 8px white
}

td.data {
  padding: 1.2rem 1.5rem 0.5rem 1.5rem;
}

td div.timing {
  font-size: 15px;
}

td div.timing-variation {
  color: red; 
  font-size: 10px;
  height: 15px;
}

td div.count {
  color: #717171; 
  font-size: 12px;
  height: 15px;
}
